import { Form, Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd/es/upload'
import { strapiApi } from '../../../api'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from '../../../hooks/useWindowsDimensions'

export interface Step3FormValues {
    user: {
        [key: string]: any
    }
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
}

const Step3 = ({
    form,
    onFinishStep3,
    step3Values,
    strapiId,
    setLoading,
}: {
    form: any
    onFinishStep3?: any
    step3Values?: Step3FormValues
    strapiId?: number
    setLoading?: (b: boolean) => void
}) => {
    const { t } = useTranslation()
    const { width } = useWindowDimensions()

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e
        }
        return e?.fileList
    }
    const props: UploadProps = {
        name: 'file',
        multiple: true,

        customRequest: async ({ file, filename, onError, onProgress, onSuccess }) => {
            if (!filename || !file || !onError || !onProgress || !onSuccess) {
                return
            }
            setLoading?.(true)
            const form = new FormData()

            form.append('files', file)
            form.append('path', `i3/sollicitaties/${strapiId}`)
            form.append('ref', 'api::i3-sollicitatie.i3-sollicitatie')
            form.append('refId', strapiId?.toString() ?? '0')
            form.append('field', 'documents_cv')

            strapiApi
                .post(`/upload`, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: ({ total, loaded }) => {
                        if (!total) {
                            return
                        }
                        onProgress({ percent: Math.round((loaded / total) * 100) })
                    },
                })
                .then(({ data: response }) => {
                    onSuccess(response)
                    setLoading?.(false)
                })
                .catch(onError)

            return {
                abort() {
                    console.log('upload progress is aborted.')
                    setLoading?.(false)
                },
            }
        },
    }
    const props2: UploadProps = {
      name: 'file',
      multiple: true,

      customRequest: async ({ file, filename, onError, onProgress, onSuccess }) => {
          if (!filename || !file || !onError || !onProgress || !onSuccess) {
              return
          }
          setLoading?.(true)
          const form = new FormData()

          form.append('files', file)
          form.append('path', `i3/sollicitaties/${strapiId}`)
          form.append('ref', 'api::i3-sollicitatie.i3-sollicitatie')
          form.append('refId', strapiId?.toString() ?? '0')
          form.append('field', 'documents_cv')

          strapiApi
              .post(`/upload`, form, {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                  },
                  onUploadProgress: ({ total, loaded }) => {
                      if (!total) {
                          return
                      }
                      onProgress({ percent: Math.round((loaded / total) * 100) })
                  },
              })
              .then(({ data: response }) => {
                  onSuccess(response)
                  setLoading?.(false)
              })
              .catch(onError)

          return {
              abort() {
                  console.log('upload progress is aborted.')
                  setLoading?.(false)
              },
          }
      },
  }
    return (
        <Form {...layout} form={form} initialValues={step3Values ? step3Values : undefined} onFinish={onFinishStep3} name="nest-messages">
            <b>{t('Gelieve uw c.v. en eventuele attesten, getuigschriften up te loaden.')}</b>

            <div
                style={
                    width && width > 1400
                        ? {
                              display: 'flex',
                              flexDirection: 'row',
                              margin: '15px',
                              justifyContent: 'center',
                              alignItems: 'center',
                          }
                        : { display: 'flex', flexDirection: 'column', margin: '15px' }
                }
            >

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <h4 style={{ marginBottom: '15px' }}>{t('C.V.')}</h4>
                    <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} style={{ minWidth: '320px' }} noStyle>
                        <Upload.Dragger {...props}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <b>C.V.</b>
                            <p className="ant-upload-text" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                {t('Klik hier of sleep je c.v. in dit grijze venster')}
                            </p>
                            <p className="ant-upload-hint">{t('Meerdere bestanden mogelijk, max 10mb')} </p>
                        </Upload.Dragger>
                    </Form.Item>
        
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}
                >
                    <h4 style={{ marginBottom: '15px' }}>{t('Overige documenten')}</h4>

                    <Form.Item name="dragger2" valuePropName="fileList2" getValueFromEvent={normFile} style={{ minWidth: '320px' }} noStyle>
                        <Upload.Dragger {...props2}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <b>Overige relevante documenten</b>

                            <p className="ant-upload-text" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                {t('Klik hier of sleep de bestanden in dit grijze venster')}
                            </p>
                            <p className="ant-upload-hint">{t('Meerdere bestanden mogelijk, max 10mb')} </p>
                        </Upload.Dragger>
                    </Form.Item>
                </div>
            </div>
        </Form>
    )
}

export default Step3
