/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col, Media } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import logodark from '../../assets/images/logo/logo2.png'
import { useNavigate } from "react-router-dom";

//Import Footer link
import FooterLinks from './footer-links'
import { useTranslation } from 'react-i18next'
import { ROUTES } from '../../constants/routes'

const Footer = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const links1 = [
        { link: ROUTES.CONTACT, title: t('Contact') },
        { link: ROUTES.VOOR_WERKGEVERS, title: t('Werkgever') },
        { link: ROUTES.SOLLICITATIE, title: t('Sollicitatie') },
        { link: ROUTES.PRIVACYBELEID, title: t('Privacy beleid') },
        { link: ROUTES.UITVOERINGSBELEID, title: t('Uitvoerings beleid') },
        { link: ROUTES.COOKIESBELEID, title: 'Cookies' },
    ]
    return (
        <React.Fragment>
            <footer className="footer">
                <Container>
                    <Row>
                        <Col lg={4} sm={12}>
                            <div>
                                <h5 className="mb-4 footer-list-title">Massarecruit</h5>
                                <p>{'Wij gaan als selectie- en adviesbureau op zoek naar de ‘perfect match’'}</p>
                                <img src={logodark} alt="" className="logo" height="40" style={{ marginBottom: '20px' }} />
                            </div>
                        </Col>
                        <Col lg={{ size: 3, offset: 1 }} sm={12}>
                            <div>
                                <h5 className="mb-4 footer-list-title">{t('Algemeen')}</h5>
                                <ul className="list-unstyled footer-list-menu">
                                    <li>
                                        <p style={{marginBottom:"7px"}} onClick={()=>      navigate("/")}>{t('Home')}</p>
                                    </li>
                                    {links1.map((fLink, key) => (
                                        <li key={key}>
                                            <Link to={fLink.link}>{fLink.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>

                        <Col lg={4} sm={12}>
                            <div>
                                <h5 className="mb-4 footer-list-title">Contact</h5>

                                <div>
                                    <Media>
                                        <div style={{ display: 'flex' }}>
                                            <i>
                                                <FeatherIcon icon="map-pin" className="icon-dual-light icons-sm mt-1 mr-2" />
                                            </i>
                                            <Media body>
                                                <p style={{ marginLeft: '10px' }}>Schildstraat 2, 2140 Borgerhout </p>
                                            </Media>
                                        </div>
                                    </Media>
                                    <Media>
                                        <div style={{ display: 'flex' }}>
                                            <i>
                                                <FeatherIcon icon="mail" className="icon-dual-light icons-sm mt-1 mr-2" />
                                            </i>
                                            <Media body>
                                                <p style={{ marginLeft: '10px' }}>info@massarecruit.com</p>
                                            </Media>
                                        </div>
                                    </Media>
                                    <Media>
                                        <div style={{ display: 'flex' }}>
                                            <i>
                                                <FeatherIcon icon="phone" className="icon-dual-light icons-sm mt-1 mr-2" />
                                            </i>
                                            <Media body>
                                                <p style={{ marginLeft: '10px' }}>0456-20.24.57</p>
                                            </Media>
                                        </div>
                                    </Media>
                                    <Media>
                                        <div style={{ display: 'flex' }}>
                                            <i>
                                                <FeatherIcon icon="file-text" className="icon-dual-light icons-sm mt-1 mr-2" />
                                            </i>
                                            <Media body>
                                                <p style={{ marginLeft: '10px' }}>Btw: 0803187219</p>
                                            </Media>
                                        </div>
                                    </Media>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
            {/* Render footer links */}
            <FooterLinks />
        </React.Fragment>
    )
}

export default Footer
