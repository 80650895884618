import React, { useContext } from 'react'
import { Button, Card, Col, Row, Switch } from 'antd'
import { colorPaller } from '../constants/colors'
import { breakpointValues, useWindowDimensions } from '../hooks/useWindowsDimensions'
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'
import cookiemonster from '../assets/images/cookiemonster/cookie.png'
import { CheckOutlined, CloseOutlined, RollbackOutlined } from '@ant-design/icons'
import { icookiesSettings } from '../App'
import { UserContext } from '../contexts/UserContexts'
import { useTranslation } from 'react-i18next'

const CookieBanner = ({ setcookiesSettings }: { setcookiesSettings: (cs: icookiesSettings) => void }) => {
    const { breakpoint } = useWindowDimensions()
    const { t } = useTranslation()
    const [showCustomized, setshowCustomized] = React.useState<boolean>(false)
    const [customizedFunctional, setcustomizedFunctional] = React.useState<boolean>(true)
    const [customizedAnalytical, setcustomizedAnalytical] = React.useState<boolean>(false)
    const { language } = useContext(UserContext)

    const rejectCookies = () => {
        setcookiesSettings({ analytics: false, functional: false, date: new Date().toString() })
    }

    const acceptAllCookies = () => {
        setcookiesSettings({ analytics: true, functional: true, date: new Date().toString(), lang: language })
    }
    const acceptCustomizedCookies = () => {
        setcookiesSettings({
            analytics: customizedAnalytical,
            functional: customizedFunctional,
            date: new Date(),
            ...(customizedFunctional ? { lang: language } : {}),
        })
    }

    const btnStyle =
        breakpoint && breakpoint >= breakpointValues.md
            ? {
                  width: '80%',
                  fontWeight: 'bold',
                  lineHeight: 1,
                  letterSpacing: 1,
              }
            : {
                  width: '90%',
                  padding: '0',
              }

    return (
        <div
            style={{
                bottom: breakpoint && breakpoint <= breakpointValues.md ? 160 : breakpoint && breakpoint <= breakpointValues.lg ? 125 : 0,
                left: 0,
                width: '100%',
                maxHeight: '300px',
                position: 'fixed',
                zIndex: 100000,
                marginBottom: '50px',
            }}
        >
            <Card
                style={{
                    maxWidth: '80vw',
                    width: '1000px',
                    margin: '0 auto 10px auto',
                    border: `1px solid ${colorPaller.blue_primary}`,
                }}
                bodyStyle={{ padding: '5px' }}
                actions={
                    !showCustomized
                        ? [
                              ...(breakpoint && breakpoint >= breakpointValues.md
                                  ? [
                                        <Button
                                      
                                            style={{
                                                ...btnStyle,
                                                border: `1px solid ${colorPaller.blue_gradient_4}`,
                                            }}
                                            onClick={() => setshowCustomized((prev) => !prev)}
                                        >
                                            {t('Aanpassen')}
                                        </Button>,
                                    ]
                                  : []),
                              <Button type="primary" style={{ ...btnStyle }} onClick={rejectCookies} danger>
                                  {t('Weiger alles')}
                              </Button>,
                              <Button
                            
                                  style={{
                                      ...btnStyle,
                                      color: colorPaller.white,
                                      backgroundColor: colorPaller.succes_green,
                                  }}
                                  onClick={acceptAllCookies}
                              >
                                  {t('Accepteer Alles')}
                              </Button>,
                          ]
                        : []
                }
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                    }}
                >
                    <h3>
                        <span style={{ color: colorPaller.blue_primary }}>C</span>
                        <span style={{ color: colorPaller.blue_gradient_1 }}>O</span>
                        <span style={{ color: colorPaller.blue_gradient_2 }}>O</span>
                        <span style={{ color: colorPaller.blue_gradient_3 }}>K</span>
                        <span style={{ color: colorPaller.blue_gradient_4 }}>I</span>
                        <span style={{ color: colorPaller.blue_gradient_3 }}>E</span>
                        <span style={{ color: colorPaller.blue_gradient_1 }}>S</span>
                    </h3>
                    {!showCustomized ? (
                        <Row style={breakpoint && breakpoint >= breakpointValues.lg ? { height: '220px' } : {}}>
                            <Col span={breakpoint && breakpoint >= breakpointValues.lg ? 16 : 24}>
                                <p
                                    style={
                                        breakpoint && breakpoint < breakpointValues.md
                                            ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                            : { marginBottom: '5px' }
                                    }
                                >
                                    {t('cookieTextHeaderPart1')}
                                </p>
                                <p
                                    style={
                                        breakpoint && breakpoint < breakpointValues.md
                                            ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                            : { marginBottom: '5px' }
                                    }
                                >
                                    {t('cookieTextHeaderPart2')}
                                    <Link to={ROUTES.COOKIESBELEID}>
                                        {' '}
                                        <b>
                                            <u>{t('hier')}</u>
                                        </b>{' '}
                                    </Link>
                                    {t('cookieTextHeaderPart3')}
                                </p>
                            </Col>
                            {breakpoint && breakpoint >= breakpointValues.lg && (
                                <Col span={8}>
                                    <img
                                        src={cookiemonster}
                                        alt=""
                                        className="logo"
                                        style={{
                                            width: '60%',
                                            
                                            objectFit: 'fill',
                                            overflow: 'hidden',
                                        }}
                                    />
                                </Col>
                            )}
                            {breakpoint && breakpoint < breakpointValues.md && (
                                <p
                                    onClick={() => setshowCustomized((prev) => !prev)}
                                    style={{ position: 'relative', bottom: '0', marginBottom: '0px', textAlign: 'center', width: '100%' }}
                                >
                                    <u>{t('Cookies aanpassen')}</u>
                                </p>
                            )}
                        </Row>
                    ) : (
                        <React.Fragment>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    flexDirection: 'row',
                                    borderTop: `1px solid ${colorPaller.blue_gradient_4}`,
                                }}
                            >
                                <Switch
                                    style={{ height: '23px' }}
                                    defaultChecked={customizedFunctional}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={() => setcustomizedFunctional((prev) => !prev)}
                                />

                                <p style={{ margin: '5px 0 10px 15px' }}>Functionele cookies</p>
                            </div>
                            <Row style={{ textAlign: 'left' }}>
                                <Col offset={1} span={23}>
                                    <p
                                        style={
                                            breakpoint && breakpoint < breakpointValues.md
                                                ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                                : { marginBottom: '5px' }
                                        }
                                    >
                                        {t(
                                            'Deze cookies zorgen ervoor dat we je taal onthouden en dat we je niet iedere keer hoeven te vragen in welke taal je onze website wilt lezen.'
                                        )}
                                    </p>
                                </Col>
                            </Row>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'baseline',
                                    flexDirection: 'row',
                                    borderTop: `1px solid ${colorPaller.blue_gradient_4}`,
                                }}
                            >
                                <Switch
                                    style={{ height: '23px' }}
                                    defaultChecked={customizedAnalytical}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    onChange={() => setcustomizedAnalytical((prev) => !prev)}
                                />

                                <p style={{ margin: '5px 0 10px 15px' }}>Analytische cookies</p>
                            </div>

                            <Row style={{ marginBottom: '5px', textAlign: 'left', borderBottom: `1px solid ${colorPaller.blue_gradient_4}` }}>
                                <Col offset={1} span={23}>
                                    <p
                                        style={
                                            breakpoint && breakpoint < breakpointValues.md
                                                ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                                : { marginBottom: '5px' }
                                        }
                                    >
                                        {t('analyticCookiesExpl')}
                                    </p>
                                </Col>
                            </Row>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Button
                              
                                    style={{
                                        ...btnStyle,
                                        width: '10%',
                                        color: 'white',
                                        backgroundColor: colorPaller.blue_primary,
                                        border: `1px solid ${colorPaller.blue_gradient_4}`,
                                    }}
                                    onClick={() => setshowCustomized((prev) => !prev)}
                                >
                                    {<RollbackOutlined key="return" />}
                                </Button>
                                <Button
                              
                                    style={{
                                        ...btnStyle,

                                        width: '40%',

                                        border: `1px solid ${colorPaller.blue_gradient_4}`,
                                    }}
                                    onClick={acceptCustomizedCookies}
                                >
                                    <p
                                        style={
                                            breakpoint && breakpoint < breakpointValues.md
                                                ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                                : { marginBottom: '5px' }
                                        }
                                    >
                                        {t('Accepteer Selectie')}
                                    </p>
                                </Button>

                                <Button
                              
                                    style={{
                                        ...btnStyle,
                                        width: '40%',

                                        color: colorPaller.white,
                                        backgroundColor: colorPaller.succes_green,
                                    }}
                                    onClick={acceptAllCookies}
                                >
                                    <p
                                        style={
                                            breakpoint && breakpoint < breakpointValues.md
                                                ? { fontSize: '13px', lineHeight: '1.2', marginBottom: '5px' }
                                                : { marginBottom: '5px' }
                                        }
                                    >
                                        {t('Accepteer Alles')}
                                    </p>
                                </Button>
                            </div>

                            <p style={{ margin: '5px 0 0 0 ' }}>
                                {' '}
                                {t('Lees')}{' '}
                                <Link to={ROUTES.COOKIESBELEID}>
                                    <u>{t('hier')}</u>
                                </Link>{' '}
                                {t('alles over ons cookiebeleid, Je kunt in ons cookie beleid je keuze altijd achteraf aanpassen.')}
                            </p>
                        </React.Fragment>
                    )}
                </div>
            </Card>
        </div>
    )
}

export default CookieBanner
