import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import { i3_vacature, tables } from '../../api/types'
import { useQuery } from '@tanstack/react-query'
import { fetchStrapiData } from '../../api/calls'
import { Col, Row } from 'reactstrap'
import FeatherIcon from 'feather-icons-react'
import { colorPaller } from '../../constants/colors'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Button } from 'antd'
import { SendOutlined, RollbackOutlined } from '@ant-design/icons'
import Loader from '../../components/loader/Loader'
import { breakpointValues, useWindowDimensions } from '../../hooks/useWindowsDimensions'
import SollicitatieModal from './SollicitatieModal'
import { notifTypes } from '../../App'

const VacatureDetails = ({
    openNotificationWithIcon,
  }: {
    openNotificationWithIcon: (type: notifTypes) => void;
  }) => {
    const { id } = useParams()

    const [ShowSollicitatieModal, setShowSollicitatieModal] = React.useState<boolean>(false)
    const [data, setdata] = React.useState<{ attributes: i3_vacature; id: number }>()
    const { breakpoint } = useWindowDimensions()
    const navigate = useNavigate()
    if (!id) {
        navigate(-1)
    }

    const { data: response, isLoading } = useQuery<i3_vacature, any, { attributes: i3_vacature; id: number }, [tables, string]>(
        ['i3-vacatures', id as string],
        () => fetchStrapiData({ table: 'i3-vacatures', id: parseInt(id as string), populate: '*' })
    )
    React.useEffect(() => {
        if (response) {
            setdata(response)
        }
    }, [response])
    //style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}s
    return (
        <React.Fragment>
            <Helmet>
                <title>Vacature details</title>
                <meta name="description" content="Een specifieke vacature" />
                <link rel="canonical" href={`/vacatures/${id}`} />
            </Helmet>
            <section className="section" style={{ paddingLeft: '20px', paddingRight: '20px', minHeight: '600px' }}>
                 <SollicitatieModal setShowSollicitatieModal={setShowSollicitatieModal} ShowSollicitatieModal={ShowSollicitatieModal} openNotificationWithIcon={openNotificationWithIcon} vacature={data}/>
                {isLoading ? (
                    <Loader />
                ) : data && data.attributes ? (
                    <React.Fragment>
                        <Row>
                            <Col span={12} style={{ marginBottom: '15px' }}>
                                <h1 style={{ color: colorPaller.blue_primary }}>{data.attributes.main_title}</h1>
                                <b>
                                    {' '}
                                    <i style={{ marginRight: '10px' }}>
                                        <FeatherIcon
                                            style={{ color: colorPaller.blue_primary, marginBottom: '4px' }}
                                            icon="map-pin"
                                            className="icon-dual-light icons-sm mt-1 mr-2"
                                        />
                                    </i>
                                    <span style={{ color: colorPaller.blue_primary }}>
                                        {data.attributes.client_name} te {data.attributes.client_city}
                                    </span>
                                </b>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={9} style={{ color: 'black' }}>
                                <b style={{ color: colorPaller.blue_primary, fontSize: '18px' }}>Functieomschrijving</b>
                                <ReactMarkdown>{data.attributes.functie_omschrijving ?? ''}</ReactMarkdown>
                                <b style={{ color: colorPaller.blue_primary, fontSize: '18px' }}>Profiel</b>
                                <ReactMarkdown>{data.attributes.profiel ?? ''}</ReactMarkdown>
                                <b style={{ color: colorPaller.blue_primary, fontSize: '18px' }}>Jobgerelateerde competenties</b>
                                <ReactMarkdown>{data.attributes.jobgerelateerde_competenties ?? ''}</ReactMarkdown>
                                <b style={{ color: colorPaller.blue_primary, fontSize: '18px' }}>Aanbod</b>
                                <ReactMarkdown>{data.attributes.aanbod ?? ''}</ReactMarkdown>
                                <b style={{ color: colorPaller.blue_primary, fontSize: '18px' }}>Plaats tewerkstelling</b>
                                <p>{data.attributes.plaats_tewerkstelling ?? ''}</p>
                            </Col>

                            <Col md={{ offset: 3, size: 6 }} lg={{ offset: 0, size: 3 }}>
                                <div
                                    style={{
                                        border: `2px solid ${colorPaller.blue_primary}`,
                                        backgroundColor: '#f5f5f5',
                                        color: 'black',
                                        padding: '5px 10px 5px 10px',
                                        borderRadius: '15px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginBottom: '20px',
                                    }}
                                >
                                    <p style={{ color: colorPaller.blue_primary, fontSize: '14px' }}>Vereiste studies</p>
                                    {data.attributes.vereiste_studies.length ? (
                                        <ul>
                                            {data.attributes.vereiste_studies.map((vs) => (
                                                <li>{vs.text_field}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'n.v.t.'
                                    )}

                                    <p style={{ color: colorPaller.blue_primary, fontSize: '14px' }}>Werkervaring</p>
                                    {data.attributes.werk_ervaring.length ? (
                                        <ul>
                                            {data.attributes.werk_ervaring.map((vs) => (
                                                <li>{vs.text_field}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'n.v.t.'
                                    )}
                                    <p style={{ color: colorPaller.blue_primary, fontSize: '14px' }}>Talenkennis</p>
                                    {data.attributes.talen_kennis.length ? (
                                        <ul>
                                            {data.attributes.talen_kennis.map((vs) => (
                                                <li>{vs.text_field}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'n.v.t.'
                                    )}
                                    <p style={{ color: colorPaller.blue_primary, fontSize: '14px' }}>Rijbewijs</p>
                                    {data.attributes.rijbewijs.length ? (
                                        <ul>
                                            {data.attributes.rijbewijs.map((vs) => (
                                                <li>{vs.text_field}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'n.v.t.'
                                    )}
                                    <p style={{ color: colorPaller.blue_primary, fontSize: '14px' }}>Contract</p>
                                    {data.attributes.contract.length ? (
                                        <ul>
                                            {data.attributes.contract.map((vs) => (
                                                <li>{vs.text_field}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        'n.v.t.'
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                {breakpoint && breakpoint >= breakpointValues.lg ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button
                                      
                                            style={{
                                                backgroundColor: 'white',
                                                height: 'auto',
                                                marginBottom: '15px',
                                                marginRight: '25px',

                                                color: colorPaller.blue_primary,
                                                border: `2px solid ${colorPaller.blue_gradient_4}`,
                                            }}
                                            onClick={() => navigate(-1)}
                                        >
                                            <div>{<RollbackOutlined style={{ fontSize: '25px' }} />}</div>
                                            <b>Keer terug naar overzicht</b>
                                        </Button>

                                        <Button
                                            style={{
                                                height: 'auto',
                                                marginBottom: '15px',
                                                backgroundColor: colorPaller.blue_primary,
                                                color: colorPaller.white,
                                            }}
                                            onClick={() => setShowSollicitatieModal(true)}
                                        >
                                            <div>
                                                <SendOutlined style={{ fontSize: '25px' }} />{' '}
                                            </div>
                                            <b>Solliciteer voor deze vacature</b>
                                        </Button>
                                    </div>
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Button
                                      
                                            style={{
                                                backgroundColor: 'white',
                                                height: 'auto',
                                                marginBottom: '15px',
                                                color: colorPaller.blue_primary,
                                                border: `2px solid ${colorPaller.blue_gradient_4}`,
                                            }}
                                            onClick={() => navigate(-1)}
                                        >
                                            <div>{<RollbackOutlined style={{ fontSize: '25px' }} />}</div>
                                            <b>Keer terug naar overzicht</b>
                                        </Button>

                                        <Button
                                            style={{
                                                height: 'auto',
                                                marginBottom: '15px',
                                                backgroundColor: colorPaller.blue_primary,
                                                color: colorPaller.white,
                                            }}
                                            onClick={() => setShowSollicitatieModal(true)}
                                        >
                                            <div>
                                                <SendOutlined style={{ fontSize: '25px' }} />{' '}
                                            </div>
                                            <b>Solliciteer voor deze vacature</b>
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                ) : (
                    <p>Vacature niet gevonden</p>
                )}
            </section>
        </React.Fragment>
    )
}

export default VacatureDetails
