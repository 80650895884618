import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";
import { colorPaller } from '../../constants/colors';
const HomeSection = () => {
    const { t } = useTranslation();

  return (
            <React.Fragment>
                <section className="hero-section-2 bg-primary" id="home">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={8}>
                                <div className="hero-wrapper text-center mb-4">
                                    <h1 className="hero-title text-white mb-4">{t("Wij bieden u kansen voor vaste en flexibele contracten, voor elk")} <span style={{color:colorPaller.white}}>{t("ervaringsniveau")}</span></h1>

                                    <p style={{color:colorPaller.blue_gradient_4}}>{t("Als toonaangevend recruitmentbureau zijn wij bij MassaRecruit voortdurend op zoek naar getalenteerde individuen uit alle hoeken van de arbeidsmarkt")}</p>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
  )
}

export default HomeSection