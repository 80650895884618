import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Images
import logoDark from "../../assets/images/logo/logo2.png";

class FooterLinks extends Component {
  render() {
    return (
      <React.Fragment>
        <section className="bg-primary py-1" >
          <Container>
            <Row>
              <Col lg={12} style={{display:'flex', flexDirection:"row", justifyContent:'center', alignItems:"baseline"}}>
                  <Link to="/">
                    <img src={logoDark} alt="" height="20" />
                  </Link>
                  <p className="copyright-desc text-white " style={{marginLeft:"20px", marginBottom:0}}> 
                    {new Date().getFullYear()} © Massarecruit.
                  </p>
                  <span style={{fontSize:'9px', marginLeft:"15px"}}>Created by <a href="aedesign.be" style={{color:'white'}}>AE--Design</a></span>
                </Col>
            
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default FooterLinks;
