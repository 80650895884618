import axios from "axios";

const strapiApi = axios.create({
  timeout: 20000,
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:1337/api"
      : "https://strapi-t2wv5rjn7a-ew.a.run.app/api",
  headers: {
    Authorization:
      process.env.NODE_ENV === "development"
        ? "bearer a1cf55e92aa7f6b67b72df88f45907a81d118e5b51f7a79c1119c8da74ad40c4f1357ccc5c467da8c21492284124da57d8fd5962a8cc328f36f3a129d750be3918ea28d5a1f0afeebddd2c1a173632108aa2f9688a6ea20ddca06a74de30ff6533ae5d411a88b854cdb4388ae1069d3b93ec6d0df0ebe89c6e67cf2aaf947672"
        : "bearer d284a660b0a9318f500bb8d1dd6767e14f6b095d1dadf18f1a96e333557a3ee2fb4c86fb240611a262374d3505383ffdc62c0512da47e74e993e4f2307217f1dd683f54a371bf92c875a1420f864854d87c63d5de79e50770b87a4467dd22f58b0e1ff0a0c5d48700d0413ad50e7228943d7a28c16bd183ef39189649d5b045f",
  },
});
const strapiApiProduction = axios.create({
  timeout: 20000,
  baseURL: "https://strapi-t2wv5rjn7a-ew.a.run.app/api",
  headers: {
    Authorization:
      "bearer d284a660b0a9318f500bb8d1dd6767e14f6b095d1dadf18f1a96e333557a3ee2fb4c86fb240611a262374d3505383ffdc62c0512da47e74e993e4f2307217f1dd683f54a371bf92c875a1420f864854d87c63d5de79e50770b87a4467dd22f58b0e1ff0a0c5d48700d0413ad50e7228943d7a28c16bd183ef39189649d5b045f",
  },
});


export { strapiApi,  strapiApiProduction };
